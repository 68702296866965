/* body{
  background-color: #ede8e8 !important;
} */

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  border-radius: 0% !important;
}

.nav-link {
  color: white !important;
  font-weight: 700 !important;
  /* color: white !important; */
}

@media (max-width: 992px) {
  .nav-link {
    color: black !important;
    /* color: white !important; */
  }

}

.logo-img {
  margin-left: 10px;
  width: 150px !important;

  border-radius: 50%;
  box-shadow: 0px 0px 4px #14151578;

}

@media (max-width: 992px) {
  .logo-img {
    width: 100px !important;
  }

}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: #000000 !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #d4d4d4 !important
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  /* --bs-primary-rgb: 13,110,253; */
  --bs-primary-rgb: 64, 142, 145 !important;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 133, 104, 251 !important;
  /* --bs-warning-rgb: 36, 89, 83 !important; */
  /* --bs-warning-rgb: 255,193,7; */
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  /* --bs-body-bg: #fff; */
  --bs-body-bg: #D8D8D8 !important;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}



.botton-line {
  /* background-color: green; 
	color: #333; */
  background: linear-gradient(#180ed7, #0b0b0b) no-repeat;
  background-position: bottom;
  /* 	Change the second value */
  background-size: 100% 5%;
  /* font-size: 18px; */
  /* margin-left: -0.5em; */
}



.counter-up {
  background: url("./../public/assets/bg1.jpg") no-repeat;
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.counter-up::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.counter-up .content {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.content .box {
  width: calc(25% - 30px);
  border: 1px dashed rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  flex-direction: column;
  border-radius: 5px;

}

.content .box .icon {
  font-size: 48px;
  color: #e6e6e6;
}

.content .box .counter {
  font-size: 50px;
  color: #f2f2f2;
  font-weight: 500;
  font-family: sans-serif;
}

.content .box .text {
  color: #ccc;
  font-weight: 400;
}

@media (max-width: 1036px) {
  .counter-up {
    padding: 50px 50px 0 50px;
  }

  .content .box {
    width: calc(50% - 30px);
    margin-bottom: 50px;
  }
}

@media (max-width: 580px) {

  .content .box {
    width: 100%;
  }
}

/* .css-1vw5i9y-MuiButtonBase-root-MuiTab-root {
min-width: 0px !important;
font-size: 10px !important;
} */

.font-small {
  font-size: small !important;
}

.left-line {
  border-left: 3px solid #8568fb !important;
  border-radius: 3px;
}

.manuBtn {
  position: fixed;
  top: 100px;
  right: 10px;
  z-index: 99999;
}


.manuBtn {
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: bolder;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
}

.manuBtn:hover {
  animation-play-state: paused;
  color: #9ef3b5;
}



@media (max-width: 580px) {

  .manuBtn {
    font-size: 10px;
  }
}

@keyframes glowing {
  0% {
    /* background-color: #4c1ef3; */
    box-shadow: 0 0 5px #070386;

    background: rgb(127, 222, 88);
    background: linear-gradient(90deg, rgba(127, 222, 88, 1) 0%, rgba(13, 13, 173, 1) 50%, rgba(34, 185, 158, 1) 100%);

    /* background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 50%, rgba(0, 212, 255, 1) 100%); */
  }

  50% {
    /* background-color: #040218; */
    box-shadow: 0 0 20px #0f0137;

    background: rgb(88, 201, 222);
    background: linear-gradient(90deg, rgba(88, 201, 222, 1) 0%, rgba(16, 185, 230, 1) 48%, rgba(18, 33, 193, 1) 100%);

    /* background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(31, 31, 210, 1) 50%, rgba(0, 212, 255, 1) 100%); */
  }

  100% {
    /* background-color: #3d12eb; */
    box-shadow: 0 0 5px #480990;

    background: rgb(95, 88, 222);
    background: linear-gradient(90deg, rgba(95, 88, 222, 1) 0%, rgba(13, 13, 173, 1) 50%, rgba(32, 53, 57, 1) 100%);
  }
}

.manuBtn {
  animation: glowing 2300ms infinite;
}

a{
  text-decoration: none !important;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {  
  10% { opacity: 50; color: blue }
   50% { opacity: 100; color: red }
   80% { opacity: 50; color: rgb(2, 181, 97) }
 }